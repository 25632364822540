import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Exchange = lazy(() => import('./views/Exchange'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Pools = lazy(() => import('./views/Pools'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
// const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  useEffect(() => {
    if (localStorage.getItem('IS_DARK') === 'false') {
      console.log('logic right')
      // localStorage.setItem("IS_DARK", "true")
    }
    if (localStorage.getItem('IS_DARK') === 'true') {
      console.log('logic right')
      // localStorage.setItem("IS_DARK", "true")
    } else {
      localStorage.setItem('IS_DARK', 'false')
    }
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  return (
    <div>
      <img
        src="/images/Cameras.png"
        alt="bg cameras"
        style={{ zIndex: -10, position: 'absolute', top: '10%', left: '13%', width: '87%', height: '100%' }}
      />

      <Router>
        <ResetCSS />
        <GlobalStyle />
        <Menu>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/farms">
                <Farms />
              </Route>
              <Route path="/nests">
                <Farms tokenMode />
              </Route>
              <Route path="/exchange">
                <Exchange />
              </Route>
              {/* <Route path="/pools"> */}
              {/*  <Pools /> */}
              {/* </Route> */}
              {/* <Route path="/lottery"> */}
              {/*  <Lottery /> */}
              {/* </Route> */}
              {/* <Route path="/ifo"> */}
              {/*  <Ifos /> */}
              {/* </Route> */}
              {/* <Route path="/nft"> */}
              {/*  <Nft /> */}
              {/* </Route> */}
              {/* Redirect */}
              {/* <Route path="/staking"> */}
              {/*  <Redirect to="/pools" /> */}
              {/* </Route> */}
              {/* <Route path="/syrup"> */}
              {/*  <Redirect to="/pools" /> */}
              {/* </Route> */}
              {/* 404 */}
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Menu>
        {/* <NftGlobalNotification /> */}
      </Router>
      <img
        src="/images/LowerBackground.png"
        alt="bottom bag"
        style={{ width: '100%', position: 'relative', bottom: 0, height: '120px' }}
      />
    </div>
  )
}

export default React.memo(App)
