import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  
  {
    pid: 8,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'SCX',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xAd745402E568A1880c95e7BC1B927E3BD2f11B60', // AUTO-BNB LP
    },
    tokenSymbol: 'SCX',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x73972F91fbFd8673Eafaab0105481A90D25E153d',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 0,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SCX-BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xAd745402E568A1880c95e7BC1B927E3BD2f11B60', // AUTO-BNB LP
    },
    tokenSymbol: 'SCX',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x73972F91fbFd8673Eafaab0105481A90D25E153d',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'SCX-BUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x2A12fdCBf11CA67560Bb8b37e97112694548D1fC', // AUTO-BNB LP
    },
    tokenSymbol: 'SCX',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x73972F91fbFd8673Eafaab0105481A90D25E153d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    pid: 2,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0', // AUTO-BNB LP
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },



  {
    pid: 3,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'BUSD-BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 4,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'USDC-USDT',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c', // AUTO-BNB LP
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },



  {
    pid: 5,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'USDC-BUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1', // AUTO-BNB LP
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },


  {
    pid: 6,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082', // AUTO-BNB LP
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 7,
    risk: 5,
    // isTokenOnly: true,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc', // AUTO-BNB LP
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


 
]

export default farms
